import  './utils.geolocation.ts';
import * as mobileUtils from './utils.ts';
import { defineAsyncComponent } from 'vue';
// import { globalState } from "o365-vue";
// import { ref, watch, computed } from "vue";

export { isMobile, isTablet } from "o365.GlobalState.ts";

// export const isMobile = globalState.isMobile;
// export const isMobile = ref(globalState.isMobile);
// export const isTablet = ref(globalState.isTablet);

// // just in case
// watch(globalState, (state) => {
//     isMobile.value = state.isMobile;
//     isTablet.value = state.isTablet;
// });

// export const isMobile = ref(globalState.isMobile);

// console.log("init", globalState.isMobile, isMobile.value);

export { default as MobilePlugin } from './plugin.ts';

export { default as MContainer } from "./components.Container.vue";
export { default as MContent } from "./components.Content.vue";

export { default as FolderView } from "./Register.FolderView.FolderView.vue";

/* elements */
export { default as MAlert } from "./components.Alert.vue";
export { default as MSearch } from "./components.Search.vue";
export { default as MDrawer } from "./components.Drawer.vue";
export { default as MFloatingButton } from "./components.FloatingButton.vue";
export { default as MTitleBar } from "./components.TitleBar.vue";
export { default as MDatePicker } from "./components.DatePicker.vue";
export { default as MNavbar } from "./components.Navbar.vue";
export { default as BottomSheetScanner } from "./components.BottomSheetScanner.vue";
//export { default as MOrgUnitChooser } from "./components.orgunit.OrgUnitChooser.vue";

/* tabs */
export { default as MTab } from "./components.tabs.Tab.vue";
export { default as MTabs } from "./components.tabs.Tabs.vue";
export { default as MTabBar } from "./components.tabs.TabBar.vue";
export { default as MTabBtn } from "./components.tabs.TabBtn.vue";

/* sheets */
export { default as MBottomSheet } from "./components.BottomSheet.vue";
export { default as MActionSheet } from "./components.ActionSheet.vue";
export { default as MActionSheetBtn } from "./components.ActionSheetBtn.vue";
export { default as MMapSheet } from "./components.MapSheet.vue";
export { default as MIFrameBottomSheet } from "./components.IFrameBottomSheet.vue";
export { default as MWorkflowTypesSheet } from "./components.workflow.TypesSheet.vue";

/* filter */
export { default as MFilterPills } from "./components.filter.FilterPills.vue";
export { default as MFilterSheets } from "./components.filter.FilterSheets.vue";
export { default as MFilterButtons } from './components.filter.Buttons.vue';

/* visual */
export { default as MCard } from "./components.Card.vue";
export { default as MAppCard } from "./components.AppCard.vue";
export { default as MPill } from "./components.Pill.vue";
export { default as MMSection } from "./components.Section.vue";
export { default as MAccordion } from './components.Accordion.vue';
export { default as MAccordionItem } from './components.AccordionItem.vue';
export { default as MAccordionAddItem } from './components.AccordionAddItem.vue';

export { default as MLink } from './components.Link.vue';

export { default as Filter,  getOrCreateFilter } from './controls.filter.Filter.js';

export { default as MListView } from "./components.ListView.vue";
export { default as MListItem } from "./components.ListItem.vue";

export { default as MRowCount } from "./components.RowCount.vue";

/** controls */
// export * as drawerControl from './controls.Drawer.ts';
// export * as iFrameBottomSheetControl from './controls.IFrameBottomSheet.ts';
// export * as contextControl from './controls.Context.ts'

export { default as Haptics } from "./classes.Haptics.ts";

export { default as MRegister } from "./Register.Register.vue";

export { mobileUtils };
